import { useContext } from 'react'
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import HeaderText from '../../components/header-text/header-text';
import { CommunityContext } from '../../context';
import { GET_APPOINTMENT_BY_ID } from '../../graphql/queries';
import check from '../../images/ic_check.svg';
import moment from "moment";
import Loader from '../../components/loader/loader';
import NotFound from '../errors/not-found';
import SomethingWentWrong from '../errors/something-went-wrong';

const CheckinSuccess = () => {
    const community = useContext(CommunityContext);
    let { appointment_id } = useParams();

    const { loading, error, data } = useQuery(GET_APPOINTMENT_BY_ID, {
        variables: {
            appointment_id
        }
    });

    if (loading) return <Loader />;
    if (error) return <SomethingWentWrong />;
    if (!data.getAppointmentByID) return <NotFound />

    const { getAppointmentByID: { start_datetime, csm: { first_name, last_name } } } = data

    return (
        <>
            <div style={{ height: '15%' }}>
                <HeaderText
                    bigText={community.name}
                />
            </div>
            <div className="card alert-custom-card bg-dark">
                <div className="text-center">
                    <img src={check} className="ic-check" alt="DONE" />
                    <h1 className="mt-3">
                        Check-in Successful
                    </h1>
                </div>
                <div className="text-center">
                    <p>Come on in we are excited to<br /> meet with you!</p>
                </div>
                <br />
                <div className="text-center">
                    <h6>Date & Time</h6>
                    <h6 className="text-primary">{moment(start_datetime).format('MMMM Do YYYY, h:mm a')}</h6>
                </div>
                <div className="text-center mt-2">
                    <h6>Community Sales Manager</h6>
                    <h6 className="text-primary">{first_name} {last_name}</h6>
                </div>
            </div>

        </>
    )
}


export default CheckinSuccess;