import { graphql } from "@apollo/client/react/hoc";
import {flowRight as compose} from 'lodash';

import Notification from "./notification";
import { GET_NOTIFICATION_PROPS } from "../../cacheql/queries";

const NotificationContainer = compose(
    graphql(GET_NOTIFICATION_PROPS),
)(Notification);

export default NotificationContainer;
