import { InMemoryCache, makeVar } from "@apollo/client";

export const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                notificationProps: {
                    read() {
                        return notificationPropsVar()
                    }
                }
            }
        }
    }
});


const notificationPropsInitialValue = {
    open: false,
    message: ""
}
export const notificationPropsVar = makeVar(notificationPropsInitialValue);


const appointmentRegistrationFormInitialValue = {
    first_name_1: null,
    last_name_1: null,
    primary_email_1: null,
    cell_phone_1: null,
    first_name_2: null,
    last_name_2: null,
    primary_email_2: null,
    cell_phone_2: null,
    street_address: null,
    city: null,
    state_id: null,
    zip: null,
}
export const appointmentRegistrationFormVar = makeVar(appointmentRegistrationFormInitialValue);

const learnMoreRegistrationFormInitialValue = {
    first_name_1: null,
    last_name_1: null,
    primary_email_1: null,
    cell_phone_1: null,
}
export const learnMoreRegistrationFormVar = makeVar(learnMoreRegistrationFormInitialValue);


export const selectedCommunityId = makeVar(null)
