
//import "./header-text.scss";

const HeaderText = ({
    smallText,
    bigText
}) => {
    return (
        <>
        <div className="border-custom"></div>
        <div className="title-container text-center">
            {smallText && <h6 className="sub-title mb-0">{smallText}</h6>}
            {bigText && <h1 className="title mb-0">{bigText}</h1>}
        </div>
        <div className="border-custom mb-4"></div>

        </>
    )
}


export default HeaderText