import { gql } from "@apollo/client";


const GET_ALL_COMMUNITIES = gql`
    query GetAllCommunities{
        getAllCommunities{
        id
        name
        }
    }
`;

const GET_COMMUNITY_BY_ID = gql`
  query getCommunityByID($filter:CommunityFilter){
    getAllCommunities(filter:$filter){
      id
      name
      community_schedule{
        Monday{
          start_time
          end_time
        }
        Tuesday{
          start_time
          end_time
        }
        Wednesday{
          start_time
          end_time
        }
        Thursday{
          start_time
          end_time
        }
        Friday{
          start_time
          end_time
        }
        Saturday{
          start_time
          end_time
        }
        Sunday{
          start_time
          end_time
        }
      }
    }
  }
`;


export { GET_ALL_COMMUNITIES, GET_COMMUNITY_BY_ID };