import { gql } from "@apollo/client";


const GET_APPOINTMENT_BY_ID = gql`
query GetAppointmentByID($appointment_id: String!){
    getAppointmentByID(appointment_id: $appointment_id){
        id
        start_datetime
        csm{
            first_name
            last_name
        }
    }
}
`;

export default GET_APPOINTMENT_BY_ID;