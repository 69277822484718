import gql from 'graphql-tag';

const GET_NOTIFICATION_PROPS = gql`
  query {
    notificationProps @client{
      open
      message
    }
  }
`;


export default GET_NOTIFICATION_PROPS;