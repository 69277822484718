import { Container } from 'react-bootstrap';

const CheckinSuccess = () => {
    return (
        <>
            <Container className="text-center container d-flex flex-column align-items-center" >
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <h4 className="thx-wrapper">
                        Thanks for checking out<br /> Schell Brothers!
                    </h4>
                </div>
            </Container>

        </>
    )
}


export default CheckinSuccess;