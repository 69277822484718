import React from 'react'
import './layout.css';
import { default as HeaderContent } from './header-content'
import { default as MainContent } from './main-content'
import NotificationContainer from "../../components/notification/notification-container";

const Layout = () => {
    
    return (
        <div className="App-bg">
            <HeaderContent />
            <MainContent />
            <NotificationContainer />
        </div>
    )
}


export default Layout;