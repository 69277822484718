import { gql } from "@apollo/client";

const APPOINTMENT_CHECKIN = gql`
    mutation appointmentCheckIn($input: CheckinInput!){
        appointmentCheckin(input: $input){
            code
            message
            data{
                id
            }
        }
    }
`;


export default APPOINTMENT_CHECKIN;