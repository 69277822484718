import { gql } from "@apollo/client";

const ADD_PROSPECT = gql`
    mutation addProspect($input: ProspectInput){
        addProspect(input: $input){
            success
            message
            code
            data{
                appointment_id
            }
        }
    }
`;



export default ADD_PROSPECT;