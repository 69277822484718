import { useContext } from 'react'
import "./community-landing.css"
import { useNavigate } from "react-router-dom";
import { Button, Container } from 'react-bootstrap';
import HeaderText from '../../components/header-text/header-text';
import { CommunityContext } from '../../context'
import { isWithinBusinessHours } from '../../utils/helpers';

const CommunityLanding = () => {
    const navigate = useNavigate();

    const community = useContext(CommunityContext);

    function openCheckInForm() {
        navigate(`check-in`)
    }
    function openRegistrationForm() {
        navigate(`schedule-appointment/registration-form`)
    }
    function openLearnMore() {
        if (isWithinBusinessHours(community.community_schedule))
            navigate(`learn-more/inside-bh`)
        else
            navigate(`learn-more/outside-bh`)
    }
    return (
        <>
            <Container>
                <HeaderText
                    smallText="Welcome to"
                    bigText={community.name}
                />
            </Container>
            <div className="text-center d-flex flex-column align-items-center" >
                <p>What would you like to accomplish today?</p>

                <div className="d-flex flex-column justify-content-center align-items-center">
                    <Button onClick={openRegistrationForm} className="btn-block mr-2">
                        Schedule an Appointment
                    </Button>
                    <Button onClick={openCheckInForm} className="btn-block mr-2">
                        I'm here for my scheduled appointment
                    </Button>
                    <Button onClick={openLearnMore} className="btn-block">
                        {`Learn more about ${community.name}`}
                    </Button>
                </div>


            </div>
        </>
    )
}


export default CommunityLanding;