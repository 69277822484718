import { useContext } from 'react'
import { useNavigate } from "react-router-dom";

import HeaderText from '../../components/header-text/header-text';
import TextField from '../../components/fields/text-field';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Container, Button, Row } from 'react-bootstrap';

import { useMutation } from "@apollo/client";
import { ADD_PROSPECT } from "../../graphql/mutations"
import { removeCellFormatting } from '../../utils/helpers';

import { CommunityContext } from '../../context'

import { notificationPropsVar, learnMoreRegistrationFormVar } from "../../app/cache"

import * as validations from "../../utils/common-validations";
import * as errorMessages from "../../utils/form-errors";

const RegistrationForm = () => {
    const navigate = useNavigate();

    const community = useContext(CommunityContext);

    const [addProspectFunction] = useMutation(ADD_PROSPECT);


    Yup.addMethod(Yup.mixed, "validateCell", function (message) {
        const msg = message;
        return this.test("equalTo", msg, function (value) {
            const val = removeCellFormatting(value)
            return (
                (val && /^[0-9]{10}$/.test(val)) ||
                val === null ||
                val === undefined ||
                val === ""
            );
        });
    });

    function getProspectCreationInput(values) {
        const data = Object.assign({}, values);
        data.cell_phone_1 = removeCellFormatting(values.cell_phone_1)
        data.cell_phone_2 = removeCellFormatting(values.cell_phone_2)
        data.Prospects = { community_id: community.id }
        return data
    }

    return (
        <>
            <HeaderText type="h1" bigText="Great to have you back!" />

            <Formik
                initialValues={{
                    first_name_1: '',
                    last_name_1: '',
                    primary_email_1: '',
                    cell_phone_1: ''
                }}
                validationSchema={Yup.object().shape({
                    first_name_1: validations.name.required(errorMessages.REQUIRED_FIELD),
                    last_name_1: validations.name.required(errorMessages.REQUIRED_FIELD),
                    primary_email_1: Yup.string()
                        .when("cell_phone_1", {
                            is: (cell_phone_1) => !cell_phone_1,
                            then: validations.email
                                .required(errorMessages.REQUIRED_FIELD),
                            otherwise: validations.email
                                .nullable(),
                        }),
                    cell_phone_1: Yup.string()
                        .when("primary_email_1", {
                            is: (primary_email_1) => !primary_email_1,
                            then: Yup.string()
                                .nullable()
                                .validateCell(errorMessages.CELL_LENGTH)
                                .required(errorMessages.REQUIRED_FIELD),
                            otherwise: Yup.string()
                                .nullable()
                                .validateCell(errorMessages.CELL_LENGTH),
                        }),
                }, ['primary_email_1', 'cell_phone_1'])}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        setSubmitting(true);
                        learnMoreRegistrationFormVar(values)
                        const formData = getProspectCreationInput(values)
                        const { data: { addProspect } } = await addProspectFunction({
                            variables: {
                                input: {
                                    ...formData
                                }
                            }
                        })
                        if (addProspect.code === 200) {
                            navigate(`../learn-more/success`)
                        }
                        else {
                            notificationPropsVar({
                                open: true,
                                message: "Registration failed!",
                            })
                        }
                    } catch (error) {
                        console.log(error)
                    }
                    finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({ values, isSubmitting }) => (
                    <Form>
                        <Container className="px-4">
                            <div>
                                <Row>
                                    <div className="text-center">
                                        <p>Please confirm your information below, and we'll be right with you.</p>
                                    </div>
                                </Row>
                                <Row>
                                    <h2 className="form-sub-headings">Your Information</h2>
                                </Row>
                                <Row className="form-group">
                                    <TextField
                                        label="First Name"
                                        name="first_name_1"
                                        type="text"
                                        required={true}
                                    />
                                </Row>

                                <Row className="form-group">
                                    <TextField
                                        name="last_name_1"
                                        label="Last Name"
                                        type="text"
                                        required={true}
                                    />
                                </Row>
                                <Row className="form-group">
                                    <TextField
                                        label="Email Address"
                                        name="primary_email_1"
                                        type="email"
                                        required={!values.cell_phone_1 || false}
                                    />
                                </Row>
                                <Row className="form-group">
                                    <TextField
                                        label="Cell#"
                                        name="cell_phone_1"
                                        type="cellphone"
                                        required={!values.primary_email_1 || false}
                                    />
                                </Row>

                                <Row className="form-group">
                                    <Button type="submit" disabled={isSubmitting} className="btn-block">
                                        Register
                                    </Button>
                                </Row>

                            </div>

                        </Container>
                    </Form>
                )}
            </Formik>
        </>
    )
}


export default RegistrationForm;