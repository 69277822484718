import { useContext } from 'react'
import { useNavigate } from "react-router-dom";

import HeaderText from '../../components/header-text/header-text';
import { Container, Button} from 'react-bootstrap';

import { CommunityContext } from '../../context'

import { getTodaysTimings } from '../../utils/helpers';
import moment from 'moment';


const LearnMoreOutsideBH = () => {
    const navigate = useNavigate();

    const community = useContext(CommunityContext);

    const { startDateTime, endDateTime } = getTodaysTimings(community.community_schedule)

    function openRegistrationForm() {
        navigate(`../schedule-appointment/registration-form`)
    }
    function openThanksForCheckingOut() {
        navigate(`thanks-for-checking-out`)
    }

    return (
        <>
            <Container>
                <HeaderText bigText={community.name} />
            </Container>

            <div className="text-center">
                    <div className="d-flex flex-column mt-1">
                        {<p className="font-weight-bold text-white">Sorry we missed you!</p>}
                        <p>
                        Our normal hours are <span style={{"color":"#80B602"}}>{moment(startDateTime).format('LT')} - {moment(endDateTime).format('LT')}</span>.<br/>
                        Would you like to schedule a visit or have a team member reach out to you?
                        </p>
                    </div>
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <Button onClick={openRegistrationForm} className="btn-block mr-2">
                        Yes
                    </Button>
                    <Button onClick={openThanksForCheckingOut} variant="secondary" className="btn-block mr-2">
                        No
                    </Button>
                </div>

            </div>
        </>
    )
}


export default LearnMoreOutsideBH;