import { FormControl } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { phoneDisplay, removeCellFormatting } from '../../utils/helpers';

const TextField = ({ label, required, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta, helpers] = useField(props);

    const handleOnChange = (e) => {
        //if field type is cellphone then apply formatting
        if (props.type === "cellphone") {
            let unFormattedValue = removeCellFormatting(e.target.value)
            if (unFormattedValue.length <= 10) {
                let formattedValue = phoneDisplay(unFormattedValue)
                helpers.setValue(formattedValue)
            }
        }
        else {
            helpers.setValue(e.target.value)
        }
    }

    const style = required ? { borderLeftColor: "red", borderLeftWidth: 2, background: "" } : { background: "" }

    return (
        <>
            <label htmlFor={props.id}>{label}</label>
            <FormControl
                aria-describedby={props.id}
                style={style}
                {...field}
                {...props}
                onChange={handleOnChange}
            />
            {meta.touched && meta.error && <div className="invalid-feedback">{meta.error}</div>}
        </>
    );
};

TextField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
}

export default TextField;
