import { useNavigate } from "react-router-dom";

import HeaderText from '../../components/header-text/header-text';
import { Container, Button, Row } from 'react-bootstrap';


const LearnMoreInsideBH = () => {
    const navigate = useNavigate();

    function openFirstTimeForm() {
        navigate(`first-visit`)
    }
    function openReturningForm() {
        navigate(`returning`)
    }

    return (
        <>
            <HeaderText type="h1" bigText="Awesome!" />

            <Container className="text-center container d-flex flex-column align-items-center" >
                <div>
                    <Row>
                        <p>Can't wait to point you in the right direction. Is this your first time here?</p>
                    </Row>

                    <Button onClick={openFirstTimeForm} className="btn-block mr-2">
                        Yes
                    </Button>
                    <Button onClick={openReturningForm} variant="secondary" className="btn-block mr-2">
                        No
                    </Button>

                </div>

            </Container>
        </>
    )
}


export default LearnMoreInsideBH;