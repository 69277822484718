import { useState } from 'react'
import { useNavigate } from "react-router-dom";

import HeaderText from '../../components/header-text/header-text';
import TextField from '../../components/fields/text-field';
import SelectField from '../../components/fields/select-field';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Container, Button, Row, FormCheck } from 'react-bootstrap';

import { useQuery } from "@apollo/client";
import { GET_ALL_STATES } from "../../graphql/queries"
import { removeCellFormatting } from '../../utils/helpers';

import { appointmentRegistrationFormVar } from "../../app/cache";

import * as validations from "../../utils/common-validations";
import * as errorMessages from "../../utils/form-errors";

const RegistrationForm = () => {
    const navigate = useNavigate();

    const [additionalDetailsCheck, setAdditionalDetailsCheck] = useState(false)

    const { data: statesData, loading: statesLoading, error: statesError } = useQuery(GET_ALL_STATES)

    Yup.addMethod(Yup.mixed, "validateCell", function (message) {
        const msg = message;
        return this.test("equalTo", msg, function (value) {
            const val = removeCellFormatting(value)
            return (
                (val && /^[0-9]{10}$/.test(val)) ||
                val === null ||
                val === undefined ||
                val === ""
            );
        });
    });

    function handleAdditionalDetailsToggle() {
        setAdditionalDetailsCheck(!additionalDetailsCheck)
    }

    return (
        <>
            <HeaderText type="h1" bigText="Step 1 of 2: Register" />

            <Formik
                initialValues={{
                    first_name_1: '',
                    last_name_1: '',
                    primary_email_1: '',
                    cell_phone_1: '',
                    first_name_2: '',
                    last_name_2: '',
                    primary_email_2: '',
                    cell_phone_2: '',
                    street_address: '',
                    city: '',
                    state_id: null,
                    zip: '',
                }}
                validationSchema={Yup.object().shape({
                    first_name_1: validations.name.required(errorMessages.REQUIRED_FIELD),
                    last_name_1: validations.name.required(errorMessages.REQUIRED_FIELD),
                    primary_email_1: Yup.string()
                        .when("cell_phone_1", {
                            is: (cell_phone_1) => !cell_phone_1,
                            then: validations.email
                                .required(errorMessages.REQUIRED_FIELD),
                            otherwise: validations.email
                                .nullable(),
                        }),
                    cell_phone_1: Yup.string()
                        .when("primary_email_1", {
                            is: (primary_email_1) => !primary_email_1,
                            then: Yup.string()
                                .nullable()
                                .validateCell(errorMessages.CELL_LENGTH)
                                .required(errorMessages.REQUIRED_FIELD),
                            otherwise: Yup.string()
                                .nullable()
                                .validateCell(errorMessages.CELL_LENGTH),
                        }),
                    first_name_2: validations.name,
                    last_name_2: validations.name,
                    primary_email_2: validations.email,
                    cell_phone_2: Yup.string()
                        .nullable()
                        .validateCell(Yup.ref("cell_phone_2"), errorMessages.CELL_LENGTH),
                    street_address: validations.isSpace
                        .max(128, errorMessages.ADDRESS_LONG)
                        .nullable(),
                    city: validations.isSpace.max(24, errorMessages.CITY_LONG).nullable(),
                    state_id: Yup.string().default("Select...").nullable(),
                    zip: validations.isSpace.max(10, errorMessages.ZIP_LONG).nullable(),
                }, ['primary_email_1', 'cell_phone_1'])}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        appointmentRegistrationFormVar(values)
                    } catch (error) {
                        console.log(error)
                    }
                    finally {
                        setSubmitting(false);
                    }
                    navigate(`../schedule-appointment/appointment-form`)
                }}
            >
                {({ values }) => (
                    <Form>
                        <Container className="px-4">
                            <div>
                                <Row>
                                    <h2 className="form-sub-headings">Your Information</h2>
                                </Row>
                                <Row className="form-group">
                                    <TextField
                                        label="First Name"
                                        name="first_name_1"
                                        type="text"
                                        required={true}
                                    />
                                </Row>

                                <Row className="form-group">
                                    <TextField
                                        name="last_name_1"
                                        label="Last Name"
                                        type="text"
                                        required={true}
                                    />
                                </Row>
                                <Row className="form-group">
                                    <TextField
                                        label="Email Address"
                                        name="primary_email_1"
                                        type="email"
                                        required={!values.cell_phone_1 || false}
                                    />
                                </Row>
                                <Row className="form-group">
                                    <TextField
                                        label="Cell#"
                                        name="cell_phone_1"
                                        type="cellphone"
                                        required={!values.primary_email_1 || false}
                                    />
                                </Row>
                                <Row className="form-group">
                                    <FormCheck
                                        type="checkbox"
                                        label="Additional Details"
                                        onChange={handleAdditionalDetailsToggle}
                                    />
                                </Row>

                                {
                                    additionalDetailsCheck &&
                                    <>
                                        <Row>
                                            <h2 className="mt-1 mb-3">Secondary Guest Info</h2>
                                        </Row>
                                        <Row className="form-group">
                                            <TextField
                                                label="First Name"
                                                name="first_name_2"
                                                type="text"
                                            />
                                        </Row>

                                        <Row className="form-group">
                                            <TextField
                                                name="last_name_2"
                                                label="Last Name"
                                                type="text"
                                            />
                                        </Row>
                                        <Row className="form-group">
                                            <TextField
                                                label="Email Address"
                                                name="primary_email_2"
                                                type="email"
                                            />
                                        </Row>
                                        <Row className="form-group mb-5">
                                            <TextField
                                                label="Cell#"
                                                name="cell_phone_2"
                                                type="cellphone"
                                            />
                                        </Row>

                                        <Row className="form-group">
                                            <TextField
                                                label="Address"
                                                name="street_address"
                                                type="text"
                                            />
                                        </Row>
                                        <Row className="form-group">
                                            <TextField
                                                label="City"
                                                name="city"
                                                type="text"
                                            />
                                        </Row>
                                        <Row className="form-group">
                                            <SelectField
                                                label="State"
                                                name="state_id"
                                                type="text"
                                            >
                                                <option value="">{statesLoading ? "Loading..." : statesError ? statesError.message : "Select..."}</option>
                                                {
                                                    statesData &&
                                                    statesData.getAllStates.map((state) => ((
                                                        <option key={state.id} value={state.id}>
                                                            {state.name}
                                                        </option>
                                                    )))
                                                }
                                            </SelectField>
                                        </Row>
                                        <Row className="form-group">
                                            <TextField
                                                label="Zip"
                                                name="zip"
                                                type="text"
                                            />
                                        </Row>

                                    </>
                                }

                                <Row className="form-group">
                                    <Button type="submit" className="btn-block">
                                        Register
                                    </Button>
                                </Row>

                            </div>

                        </Container>
                    </Form>
                )}
            </Formik>
        </>
    )
}


export default RegistrationForm;