import {
  ApolloClient,
  createHttpLink
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { cache } from "./cache"

const authLink = setContext(async () => {
  return {
    headers: {
    }
  };
});
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_TRAFFIC_BACKEND_HOST_URL,
});


const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});

export default client;