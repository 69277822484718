import gql from "graphql-tag";

const GET_CSM_AVAILABILITY_TIMESLOTS = gql`
query getCsmAvailabilityTimeSlots($filter: getCsmAvailabilityTimeSlotsFilter!) {
  getCsmAvailabilityTimeSlots(filter: $filter) {
    StartDateTime
    EndDateTime
  }
}
`;

export default GET_CSM_AVAILABILITY_TIMESLOTS;
