import React from 'react'
import './layout.css';
import { Outlet } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import Loader from '../../components/loader/loader';
import { CommunityContext } from '../../context'
import { GET_COMMUNITY_BY_ID } from '../../graphql/queries'
import { selectedCommunityId } from "../../app/cache"
import NotFound from '../errors/not-found';
import SomethingWentWrong from '../errors/something-went-wrong';

const CommunityRoute = () => {

    let { community_id } = useParams();


    const { loading, error, data } = useQuery(GET_COMMUNITY_BY_ID, {
        variables: {
            filter: {
                id: parseInt(community_id)
            }
        }
    });

    if (loading) return <Loader />
    if (error) return <SomethingWentWrong />;
    if (!data.getAllCommunities.length) return <NotFound />;

    selectedCommunityId(community_id)

    return (
        <CommunityContext.Provider value={data.getAllCommunities?.[0]}>
            <Outlet />
        </CommunityContext.Provider>
    )
}


export default CommunityRoute;