import './layout.css';
import { Route, Routes } from "react-router-dom";
import { Container } from 'react-bootstrap';

import Landing from '../landing'
import CommunityRoute from './community-route';

import CommunityLanding from '../community-landing/community-landing'
import CheckinForm from '../checkin/checkin-form'
import CheckinSuccess from '../checkin/checkin-success'
import RegistrationForm from '../schedule-appointment/registration-form'
import AppointmentFormContainer from '../schedule-appointment/appointment-form-container'
import ScheduleSuccess from '../schedule-appointment/schedule-success'
import LearnMoreOutsideBH from '../learn-more/outside-bh'
import ThanksForCheckingOut from '../learn-more/thanks'
import LearnMoreInsideBH from '../learn-more/inside-bh'
import FirstVisitRegistration from '../learn-more/first-registration-form'
import ReturningRegistration from '../learn-more/returning-registration-form'
import LearnMoreSuccess from '../learn-more/success'
import NotFound from '../errors/not-found';


export default function MainContent() {
    return (
        <Container className="container-main w-md-70">
            <Routes>
                <Route
                    exact
                    path="/"
                    element={<Landing />}
                />

                <Route
                    exact
                    path="/communities/:community_id"
                    element={<CommunityRoute />}
                >
                    <Route
                        index
                        element={<CommunityLanding />}
                    />
                    <Route
                        exact
                        path="check-in"
                        element={<CheckinForm />}
                    />
                    <Route
                        exact
                        path="check-in/success/:appointment_id"
                        element={<CheckinSuccess />}
                    />
                    <Route
                        exact
                        path="schedule-appointment/registration-form"
                        element={<RegistrationForm />}
                    />
                    <Route
                        exact
                        path="schedule-appointment/appointment-form"
                        element={<AppointmentFormContainer />}
                    />
                    <Route
                        exact
                        path="schedule-appointment/success/:appointment_id"
                        element={<ScheduleSuccess />}
                    />
                    <Route
                        exact
                        path="learn-more/outside-bh"
                        element={<LearnMoreOutsideBH />}
                    />
                    <Route
                        exact
                        path="learn-more/outside-bh/thanks-for-checking-out"
                        element={<ThanksForCheckingOut />}
                    />
                    <Route
                        exact
                        path="learn-more/inside-bh"
                        element={<LearnMoreInsideBH />}
                    />
                    <Route
                        exact
                        path="learn-more/inside-bh/first-visit"
                        element={<FirstVisitRegistration />}
                    />
                    <Route
                        exact
                        path="learn-more/inside-bh/returning"
                        element={<ReturningRegistration />}
                    />
                    <Route
                        exact
                        path="learn-more/success"
                        element={<LearnMoreSuccess />}
                    />
                </Route>

                {/* This Route must always be the Last.Please add new routes above it... */}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Container>
    )
}