import { useParams, useNavigate } from "react-router-dom";

import HeaderText from '../../components/header-text/header-text';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { useMutation } from "@apollo/client";
import { ADD_PROSPECT } from "../../graphql/mutations"

import { appointmentRegistrationFormVar } from "../../app/cache";
import { notificationPropsVar } from "../../app/cache"

import { removeCellFormatting } from '../../utils/helpers';

import * as errorMessages from "../../utils/form-errors";

import moment from "moment"

import AppointmentForm from './appointment-form'

const AppointmentFormContainer = () => {
    const navigate = useNavigate();

    let { community_id } = useParams();

    const [addProspectFunction] = useMutation(ADD_PROSPECT);

    const customerInfo = appointmentRegistrationFormVar()

    function transformProspectApptData(values) {
        return {
            appt_date: moment(values.appt_date).format('LL'),
            start_datetime: new Date(values.appt_timeslot.split('__')[0]),
            end_datetime: new Date(values.appt_timeslot.split('__')[1]),
            appointment_type_id: parseInt(values.appointment_type_id.split('__')[0]),
        }
    }

    function transformProspectData(values) {
        return {
            community_id: parseInt(community_id),
            Prospect_Appointments: transformProspectApptData(values)
        }
    }

    function getProspectApptCreationInput(values) {
        const data = Object.assign({}, customerInfo);
        data.primary_email_1 = customerInfo.primary_email_1 || null
        data.cell_phone_1 = removeCellFormatting(customerInfo.cell_phone_1) || null
        data.primary_email_2 = customerInfo.primary_email_2 || null
        data.cell_phone_2 = removeCellFormatting(customerInfo.cell_phone_2) || null
        data.Prospects = transformProspectData(values)
        return data
    }

    return (
        <>
            <HeaderText type="h1" bigText="Step 2 of 2: Schedule" />

            <Formik
                initialValues={{
                    appt_date: new Date(),
                    appointment_type_id: "",
                    appt_timeslot: "",
                }}
                validationSchema={Yup.object().shape({
                    appt_date: Yup.date().required(),
                    appointment_type_id: Yup.string()
                        .default("")
                        .required(errorMessages.REQUIRED_FIELD)
                        .nullable(),
                    appt_timeslot: Yup.string()
                        .default("")
                        .required(errorMessages.REQUIRED_FIELD)
                        .nullable(),
                    // start_datetime: Yup.date().required(),
                    // end_datetime: Yup.date().required(),
                })}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        setSubmitting(true);
                        const formData = getProspectApptCreationInput(values)
                        const { data: { addProspect } } = await addProspectFunction({
                            variables: {
                                input: {
                                    ...formData
                                }
                            }
                        })

                        if (addProspect.code === 200) {
                            navigate(`../schedule-appointment/success/${addProspect.data.appointment_id}`)
                        }
                        else {
                            notificationPropsVar({
                                open: true,
                                message: "Appointment creation failed!",
                            })
                        }
                    } catch (error) {
                        console.log(error)
                    }
                    finally {
                        setSubmitting(false);
                    }
                }}
            >
                {
                    (props) => <AppointmentForm {...props} />
                }
            </Formik>
        </>
    )
}


export default AppointmentFormContainer;