import PropTypes from "prop-types";
import DatePicker from "react-date-picker";
import { useField } from 'formik';

// import "./fields.css";

/**
 * This field uses react date-picker, which opens calendar in a popup from which a date can be selected.
 * @param {Object} props 
 * @param {Date} props.value value of the date
 * @param {Date} props.minDate restricting to select date earlier than a specific date
 * @param {Date} props.maxDate restricting to select date after a specific date
 * @param {Boolean} props.disabled to disable date selection
 */
const DatePickerField = ({ label, required, ...props }) => {

  const [field, meta, helpers] = useField(props);

  return (
    <>
      <label htmlFor={props.id}>{label}</label>
      <div className={required ? "date-required" : "date"}>
        <DatePicker
          aria-describedby={props.id}
          {...field}
          {...props}
          onChange={e => {
            helpers.setValue(e);
          }}
          value={props.value}
          calendarIcon={null}
          clearIcon={null}
          format="MM/dd/y"
        />
      </div>
      {meta.touched && meta.error && <div className="invalid-feedback">{meta.error}</div>}
    </>
  );
};

DatePickerField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
};

export default DatePickerField;
