import { useContext } from 'react'
import HeaderText from '../../components/header-text/header-text';
import { CommunityContext } from '../../context';
import check from '../../images/ic_check.svg';
import { learnMoreRegistrationFormVar } from "../../app/cache";

const LearnMoreSuccess = () => {
    const community = useContext(CommunityContext);

    const customerInfo = learnMoreRegistrationFormVar()

    return (
        <>
            <div style={{ height: '15%' }}>
                <HeaderText
                    bigText={community.name}
                />
            </div>
            <div className="card alert-custom-card bg-dark">
                <div className="text-center">
                    <img src={check} className="ic-check" alt="DONE" />
                    <h1 className="mt-3">
                        Registration Successful
                    </h1>
                </div>
                <div className="text-center">
                    <p>Thanks {customerInfo.first_name_1}! We may be in an appointment, but come on in! One of our team members will be with you shortly.</p>
                </div>
            </div>

        </>
    )
}


export default LearnMoreSuccess;