import moment from "moment";

/**
 * This function is used to format string into following format "(703) 967-0631"
 * @param {String} cell cell string containing 10 characters 
 * @returns {String}
 */
export const phoneDisplay = (cell) => {
  if (!cell) return "";

  if (cell.length > 0 && cell.length <= 3) return cell;

  if (cell.length > 3 && cell.length <= 6)
    return "(" + cell.slice(0, 3) + ") " + cell.slice(3, 6);

  return (
    "(" +
    cell.slice(0, 3) +
    ")" +
    " " +
    cell.slice(3, 6) +
    "-" +
    cell.slice(6, 10)
  );
};


/**
 * This function removes formatting charaters from the string
 * @param {String} formattedCell formatted cell string 
 * @returns {String}
 */
export const removeCellFormatting = (formattedCell = "") => {
  return formattedCell.replace(/[^0-9]/g, "")
};

/**
 * A function that returns the day of the date specified
 * @param {DateTime} date The date from which to extract the day
 */
export function getDayOfWeek(date) {
  return moment(new Date(date)).format("dddd");
}

/**
 * A function that applies the time provided to the date specified
 * @param {DateTime} date The date to which the time is to be applied
 * @param {String} time The time to add to the date
 */
export function setTimeToDate(date, time) {
  const [hour, minute] = time.split(":");

  date.setHours(hour, minute, 0, 0);

  return date;
}

/**
 * A function that applies the time provided (in UTC) to the date specified
 * @param {DateTime} date The date to which the time is to be applied
 * @param {String} time The time to add to the date
 */
export function setTimeToDateLocal(date, time) {
  const [hour, minute] = time.split(":");

  date.setUTCHours(hour, minute, 0, 0);

  return date;
}

/**
 * A function that returns timings for current day of the week. Uses either provided timings
 * or sets 10am - 4pm by default
 * @param {String} weeklySchedule Weekly schedule of selected community
 */
export const getTodaysTimings = (weeklySchedule) => {
  const currentDateTime = new Date()

  let startDateTime = setTimeToDate(new Date(currentDateTime), "10:00")
  let endDateTime = setTimeToDate(new Date(currentDateTime), "16:00")

  const dayOfTheWeek = getDayOfWeek(currentDateTime);

  if (weeklySchedule) {
    if (weeklySchedule[dayOfTheWeek].start_time) {
      startDateTime = setTimeToDateLocal(new Date(currentDateTime), weeklySchedule[dayOfTheWeek].start_time);
    }
    if (weeklySchedule[dayOfTheWeek].end_time) {
      endDateTime = setTimeToDateLocal(new Date(currentDateTime), weeklySchedule[dayOfTheWeek].end_time);
    }
  }

  return { startDateTime, endDateTime }
}

/**
 * A function that returns the if the current time is within business hours. Uses either provided timings
 * or sets 10am - 4pm by default
 * @param {String} weeklySchedule Weekly schedule of selected community
 */
export const isWithinBusinessHours = (weeklySchedule) => {
  const currentDateTime = new Date()

  const { startDateTime, endDateTime } = getTodaysTimings(weeklySchedule)

  if (startDateTime.getTime() <= currentDateTime.getTime() && endDateTime.getTime() >= currentDateTime.getTime())
    return true
  else
    return false
}