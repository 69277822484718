import * as Yup from "yup";
import * as errorMessages from "./form-errors";

export const isSpace = Yup.string()
    .test('spaces', errorMessages.INVALID_FORMAT, val => ((val && (/\S/).test(val)) || val === null || val === undefined || val === ""))

export const spaces = Yup.string()
    .strict()
    .trim(errorMessages.INVALID_FORMAT)

export const name = isSpace
    .max(25, errorMessages.NAME_LONG)
    .default("")
    .nullable()

export const email = Yup.string()
    .test('spaces', errorMessages.EMAIL_INVALID, val => ((val && (/\S/).test(val)) || val === null || val === undefined || val === ""))
    .default("")
    .email(errorMessages.EMAIL_INVALID)
    .max(254, errorMessages.EMAIL_LONG)
    .nullable()