import logo from '../../images/logo.png';
import './layout.css';
import { selectedCommunityId } from "../../app/cache";
import { useNavigate } from "react-router-dom"


export default function HeaderContent() {
    const navigate = useNavigate()

    function redirectTo() {
        const communityId = selectedCommunityId()
        const url = communityId ? `/communities/${communityId}` : '/'
        navigate(url)
    }

    return (
        <div className="header-container" >
            <div style={{ textAlign: "center" }}>
                <img src={logo} className="App-logo" alt="logo" onClick={redirectTo} />
            </div>
        </div>
    )
}