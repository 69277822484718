import cross from '../../images/error.svg';
import { selectedCommunityId } from "../../app/cache";
import { useNavigate } from "react-router-dom"
import { Button } from 'react-bootstrap';

const SomethingWentWrong = () => {
    const navigate = useNavigate()

    function redirectTo() {
        const communityId = selectedCommunityId()
        const url = communityId ? `/communities/${communityId}` : '/'
        navigate(url)
    }


    return (
        <div className="card alert-custom-card bg-dark">
            <div className="text-center">
                <img src={cross} className="ic-check" alt="DONE" />
                <h1 className="mt-3">
                    Something went wrong!
                </h1>
            </div>
            <div className="text-center mt-2">
                <Button onClick={redirectTo} className="btn-block mr-2">
                    Back to Home
                </Button>
            </div>
        </div>

    )
}


export default SomethingWentWrong;