export const EMAIL_INVALID = "Invalid Email";
export const EMAIL_EXISTS = "Email already exists in the list";
export const EMAIL_MATCHED = "Primary and secondary emails must not match";
export const PRIMARY_MATCH = "Primary emails must not match";
export const REQUIRED_FIELD = "Required field";
export const EMAIL_SHORT = "Email too short!";
export const EMAIL_LONG = "Email too long!";
export const CELL_LENGTH = "Cell should be a number and 10 digits";
export const NAME_LONG = "Name must not be more than 25 characters";
export const CITY_LONG = "City must not be more than 24 characters";
export const STATE_LONG = "State must not be more than 24 characters";
export const ZIP_LONG = "Zip must not be more than 10 characters";
export const ADDRESS_LONG = "Street Address must not be more than 128 characters";
export const AGENCY_NAME_LONG = "Agency name must not be more than 25 characters";
export const AGENCY_ADDRESS_LONG = "Agency address must not be more than 128 characters";
export const INVALID_FORMAT = "Invalid input format";
export const DETAILS_LONG = "Details must not be more than 1024 characters";
export const TITLE_LONG = "Title must not be more than 64 characters";
export const END_TIME = "End time must be greater than start time!";
export const START_TIME = "Start time can not be in the past!";
