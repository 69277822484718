import { useNavigate, useParams } from "react-router-dom";

import SelectField from '../../components/fields/select-field';
import DatePickerField from '../../components/fields/date-picker';

import { Form } from 'formik';
import { Container, Button, Row } from 'react-bootstrap';

import { useQuery, useLazyQuery } from "@apollo/client";
import { GET_APPOINTMENT_TYPES, GET_CSM_AVAILABILITY_TIMESLOTS } from "../../graphql/queries"

import { appointmentRegistrationFormVar } from "../../app/cache";

import { appointmentTypeDurations } from "../../utils/constants";

import moment from "moment"
import { useEffect } from "react";

const AppointmentFormContainer = ({ values, isSubmitting, ...rest }) => {
    let { community_id } = useParams();
    const navigate = useNavigate();

    const { data: appointmentTypesData, loading: appointmentTypesLoading, error: appointmentTypesError } = useQuery(GET_APPOINTMENT_TYPES)
    const [getAvailableSlots, { data: availableSlotsData, loading: availableSlotsLoading, error: availableSlotsError }] = useLazyQuery(GET_CSM_AVAILABILITY_TIMESLOTS, {
        fetchPolicy: "cache-and-network",   // Used for first execution
        nextFetchPolicy: "cache-and-network" // Used for subsequent executions
    })

    const customerInfo = appointmentRegistrationFormVar()
    
    //redirect to register form if customer information is not available
    if(!customerInfo.first_name_1) navigate(`../schedule-appointment/registration-form`)

    useEffect(() => {
        if (values.appt_date && values.appointment_type_id) {
            getAvailableSlots({
                variables: {
                    filter: {
                        community_id: parseInt(community_id),
                        appt_date: moment(values.appt_date).format('LL'),
                        duration: parseInt(values.appointment_type_id.split('__')[1])
                    }
                }
            })
        }
    }, [values.appt_date, values.appointment_type_id])

    return (
        <Form>
            <Container className="px-4 text-center">
                <Row>
                    {<p className="font-weight-bold text-white w-100">{customerInfo.first_name_1}, we are excited to meet with you!</p>}
                </Row>
                <Row>
                    <p>Please select the Date and Meeting Type you prefer and we will show the available time slots.</p>
                </Row>
                <Row className="form-group">
                    <DatePickerField
                        label="Date"
                        name="appt_date"
                        value={values.appt_date}
                        minDate={new Date()}
                        required={true}
                    />
                </Row>

                <Row className="form-group">
                    <SelectField
                        label="Meeting Type"
                        name="appointment_type_id"
                        type="text"
                        required={true}
                    >
                        <option key="0" value={""}>{appointmentTypesLoading ? "Loading..." : appointmentTypesError ? appointmentTypesError.message : "Select..."}</option>
                        {
                            appointmentTypesData &&
                            appointmentTypesData.getAllAppointmentTypes.map((appointmentType, index) => ((
                                <optgroup key={index} label={appointmentType.name}>
                                    {appointmentTypeDurations[appointmentType.name].map((duration, index) => (
                                        <option key={index} value={`${appointmentType.id}__${duration}`}>
                                            {`${duration >= 60 ? `${duration / 60} hour${duration > 60 ? 's' : ''}` : `${duration} minutes`}`}
                                        </option>
                                    ))}
                                </optgroup>
                            )))
                        }
                    </SelectField>
                </Row>
                <Row className="form-group">
                    <SelectField
                        label="Time Slot"
                        name="appt_timeslot"
                        type="text"
                        required={true}
                        disabled={!(values.appt_date && values.appointment_type_id)}
                    >
                        <option value="">{availableSlotsLoading ? "Loading..." : availableSlotsError ? availableSlotsError.message : "Select..."}</option>
                        {
                            availableSlotsData &&
                            availableSlotsData.getCsmAvailabilityTimeSlots.map(({ StartDateTime, EndDateTime }, index) => ((
                                <option key={index} value={`${StartDateTime}__${EndDateTime}`}>
                                    {`${moment(StartDateTime).format('LT')} - ${moment(EndDateTime).format('LT')}`}
                                </option>
                            )))
                        }
                    </SelectField>
                </Row>

                <Row className="form-group">
                    <Button type="submit" disabled={isSubmitting} className="btn-block">
                        Schedule
                    </Button>
                </Row>

            </Container>
        </Form>
    )

}


export default AppointmentFormContainer;