import { FormControl } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useField } from 'formik';

const SelectField = ({ label, required, children, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta, helpers] = useField(props);

    const handleOnChange = (e) => {
        const value = String(e.target.value)
        let valToSet = !value ? null : isNaN(parseInt(value, 10)) || value.includes("__")
            ? value
            : parseInt(value, 10);
        helpers.setValue(valToSet)
    }

    const style = required ? { borderLeftColor: "red", borderLeftWidth: 2, background: "" } : { background: "" }

    return (
        <>
            <label htmlFor={props.id}>{label}</label>
            <FormControl
                as="select"
                style={style}
                {...field}
                {...props}
                onChange={handleOnChange}
            >
                {children}
            </FormControl>
            {meta.touched && meta.error && <div className="invalid-feedback">{meta.error}</div>}
        </>
    );
};

SelectField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
}

export default SelectField;
