import PropTypes from "prop-types";
import React from "react";
import { Alert } from "react-bootstrap";
import "./notification.css";
import { reactiveMutations } from "../../cacheql/mutations"

const { createNotification } = reactiveMutations

const propTypes = {
  autoHideDuration: PropTypes.number,
};

const defaultProps = {
  autoHideDuration: 5000
};

/**
 * This is a component that shows all the success/failure messages at the bottom right corner of the application. 
 */
class Notification extends React.Component {
  /**
   * 
   * @param {Object} props
   * @param {Number} props.autoHideDuration amount of time (in milliseconds) for which the notification alert stays on the screen 
   */
  constructor(props) {
    super(props);

    this.intervalId = null;
  }

  /**
   * This lifecycle method is called when the component is mounted, to start the timer for showing alert on the screen
   * @method
   */
  componentDidMount() {
    if (this.props.open) {
      this.intervalId = setTimeout(
        () => this.handleDismiss(),
        this.props.autoHideDuration
      );
    }
  }

  /**
   * This lifecycle method is called when the component is updated, to start the timer for showing alert on the screen if the timer is not started already, and open is passed as true in notificationProps
   * @method
   * @param {*} prevProps props of the previous render
   */
  componentDidUpdate(prevProps) {
    if (this.props.data && this.props.data.notificationProps.open !== prevProps.data.notificationProps.open && this.props.data.notificationProps.open === true) {
      this.intervalId = setTimeout(
        () => this.handleDismiss(),
        this.props.autoHideDuration
      );
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  /**
   * This method clears the timeOut interval, and sets the open property false to hide the notification.
   * @method
   */
  handleDismiss = () => {
    clearInterval(this.intervalId);
    createNotification("", false)
  };

  render() {
    const { message, open } = this.props.data && this.props.data.notificationProps;

    if (!open) {
      return <span />;
    }

    return (
      <div className="notification">
        <Alert onClose={this.handleDismiss} variant='info' dismissible>
          <p>{message}</p>
        </Alert>
      </div>
    );
  }
}

Notification.propTypes = propTypes;
Notification.defaultProps = defaultProps;

export default Notification;
