import gql from 'graphql-tag';

const GET_ALL_STATES = gql`
query GetAllStates{
        getAllStates{
        id
        name
        abbreviation
        }
    }
`;
export default GET_ALL_STATES;
