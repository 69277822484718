import { useContext } from 'react'
import { useNavigate } from "react-router-dom";

import HeaderText from '../../components/header-text/header-text';
import TextField from '../../components/fields/text-field';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Container, Button, Row } from 'react-bootstrap';
import { CommunityContext } from '../../context'

import { useMutation } from "@apollo/client";
import { APPOINTMENT_CHECKIN } from "../../graphql/mutations"
import { notificationPropsVar } from "../../app/cache"
import { removeCellFormatting } from '../../utils/helpers';

import * as validations from "../../utils/common-validations";
import * as errorMessages from "../../utils/form-errors";

const CheckinForm = () => {
    const navigate = useNavigate();

    const community = useContext(CommunityContext);

    const [appointmentCheckinFunction] = useMutation(APPOINTMENT_CHECKIN);

    Yup.addMethod(Yup.mixed, "validateCell", function (message) {
        const msg = message;
        return this.test("equalTo", msg, function (value) {
            const val = removeCellFormatting(value)
            return (
                (val && /^[0-9]{10}$/.test(val)) ||
                val === null ||
                val === undefined ||
                val === ""
            );
        });
    });

    function getCheckinInput(values) {
        const data = Object.assign({}, values);
        data.primary_email = values.primary_email || null
        data.cell_phone = removeCellFormatting(values.cell_phone) || null
        return data
    }

    return (
        <>
            <HeaderText type="h1" bigText="Check-In" />

            <Formik
                initialValues={{
                    first_name: '',
                    last_name: '',
                    primary_email: '',
                    cell_phone: ''
                }}
                validationSchema={Yup.object().shape({
                    first_name: validations.name.required(errorMessages.REQUIRED_FIELD),
                    last_name: validations.name.required(errorMessages.REQUIRED_FIELD),
                    primary_email: Yup.string()
                        .when("cell_phone", {
                            is: (cell_phone) => !cell_phone,
                            then: validations.email
                                .required(errorMessages.REQUIRED_FIELD),
                            otherwise: validations.email
                                .nullable(),
                        }),
                    cell_phone: Yup.string()
                        .when("primary_email", {
                            is: (primary_email) => !primary_email,
                            then: Yup.string()
                                .nullable()
                                .validateCell(errorMessages.CELL_LENGTH)
                                .required(errorMessages.REQUIRED_FIELD),
                            otherwise: Yup.string()
                                .nullable()
                                .validateCell(errorMessages.CELL_LENGTH),
                        }),
                }, ['primary_email', 'cell_phone'])}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        setSubmitting(true);
                        const formData = getCheckinInput(values)

                        const { data: { appointmentCheckin } } = await appointmentCheckinFunction({
                            variables: {
                                input: {
                                    ...formData,
                                    "community_id": community.id
                                }
                            }
                        })

                        if (appointmentCheckin.code === 200) {
                            navigate(`success/${appointmentCheckin.data.id}`)
                        }
                        else {
                            notificationPropsVar({
                                open: true,
                                message: "Sorry! That doesn’t seem to match our records. Do you use another phone number or email address? Try that!",
                            })
                        }
                    } catch (error) {
                        console.log(error)
                    }
                    finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({ values, isSubmitting }) => (
                    <Form>
                        <Container className="px-4">
                            <div>
                                <Row>
                                    <h2 className="form-sub-headings">Your Information</h2>
                                </Row>
                                <Row className="form-group">
                                    <TextField
                                        label="First Name"
                                        name="first_name"
                                        type="text"
                                        required={true}
                                    />
                                </Row>

                                <Row className="form-group">
                                    <TextField
                                        name="last_name"
                                        label="Last Name"
                                        type="text"
                                        required={true}
                                    />
                                </Row>
                                <Row className="form-group">
                                    <TextField
                                        label="Email Address"
                                        name="primary_email"
                                        type="email"
                                        required={!values.cell_phone || false}
                                    />
                                </Row>
                                <Row className="form-group">
                                    <TextField
                                        label="Cell#"
                                        name="cell_phone"
                                        type="cellphone"
                                        required={!values.primary_email || false}
                                    />
                                </Row>

                                <Row className="form-group">
                                    <Button type="submit" disabled={isSubmitting} className="btn-block">
                                        Check-In
                                    </Button>
                                </Row>

                            </div>

                        </Container>
                    </Form>
                )}
            </Formik>
        </>
    )
}


export default CheckinForm;