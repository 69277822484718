import { Dropdown } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ALL_COMMUNITIES } from '../../graphql/queries'

const Landing = () => {
    const { loading, data } = useQuery(GET_ALL_COMMUNITIES);
    const navigate = useNavigate();

    function openCommunityLandingPage(id) {
        navigate(`/communities/${id}`)
    }
    return (
        <div className="d-flex flex-column align-items-center">
            <p className="m-0 mb-3 mt-2">
                Please select a community
            </p>
            <Dropdown className="dropdown-community-selection w-50">
                <Dropdown.Toggle className="btn-block" id="dropdown-basic">
                    {loading ? 'Loading...' : 'All Communities'}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {
                        data?.getAllCommunities?.map((community) =>
                            <Dropdown.Item key={community.id} onClick={() => openCommunityLandingPage(community.id)}>{community.name}</Dropdown.Item>
                        )
                    }
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}


export default Landing;